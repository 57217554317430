import {register} from 'register-service-worker';

register('/service-worker.js', {
  registrationOptions: {
    scope: './',
  },
  ready(registration) {
    // tslint:disable no-console
    console.info('Service worker is active.');
  },
  registered(registration) {
    console.info('Service worker has been registered.');
  },
  cached(registration) {
    console.info('Content has been cached for offline use.');
  },
  updatefound(registration) {
    console.info('New content is downloading.');
  },
  updated(registration) {
    console.info('New content is available; please refresh.');
  },
  offline() {
    console.info('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
