













import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  components: {},
})
export default class Filterbar extends Vue {
  public isActive: boolean = false;

  public toggleActive() {
    this.isActive = !this.isActive;
  }
}
