





















import Component from 'vue-class-component';
import Vue from 'vue';
import VueMeta, { MetaInfo } from 'vue-meta';
Vue.use(VueMeta);

@Component({
   metaInfo(this: NotFound): MetaInfo {
        return {
            title: 'Nothing Found | bo-ma.net',
            meta: [
                { charset: 'utf-8' },
                {
                    name: 'title',
                    content: 'Nothing Found | bo-ma.net',
                    vmid: 'title',
                },
                {
                    name: 'description',
                    content: 'Oh! Your looking for something special ... sorry that you found nothing :)',
                    vmid: 'description',
                },
            ],
        };
    },
})



export default class NotFound extends Vue {
  //
}
