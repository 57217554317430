















import Component from 'vue-class-component';
import Vue from 'vue';
import VueMeta, { MetaInfo } from 'vue-meta';
Vue.use(VueMeta);

@Component({
    metaInfo(this: Home): MetaInfo {
        return {
            title: 'Marc Böhm | bo-ma.net',
            meta: [
                { charset: 'utf-8' },
                {
                    name: 'title',
                    content: 'Marc Böhm | bo-ma.net',
                    vmid: 'title',
                },
                {
                    name: 'description',
                    content: 'Hello, I’m working as creative director and web developer. Based in Jena, Germany.',
                    vmid: 'description',
                },
            ],
        };
    },
})

export default class Home extends Vue {
    //
}
