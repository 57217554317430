


















import Component from 'vue-class-component';
import Vue from 'vue';
import VueMeta, { MetaInfo } from 'vue-meta';
Vue.use(VueMeta);


@Component({
   metaInfo(this: Imprint): MetaInfo {
        return {
            title: 'Imprint | bo-ma.net',
            meta: [
                { charset: 'utf-8' },
                {
                    name: 'title',
                    content: 'Imprint | bo-ma.net',
                    vmid: 'title',
                },
            ],
        };
    },
})
export default class Imprint extends Vue {

}
