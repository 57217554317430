import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import Home from './views/Home.vue';
import Portfolio from './views/Portfolio.vue';
import NotFound from './views/NotFound.vue';
import Imprint from './views/AboutImprint.vue';

import Intro from './components/Intro.vue';
import Navigation from './components/Navigation.vue';
import Footer from './components/Footer.vue';

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'home',
      components: {
        default: Home,
        intro: Intro,
        navigation: Navigation,
        footer: Footer,
      },
    },
    // {
    //   path: '/portfolio',
    //   name: 'portfolio',
    //   components: {
    //     default: Portfolio,
    //     intro: Intro,
    //     navigation: Navigation,
    //     footer: Footer,
    //   },
    // },
    {
      path: '*',
      // Replace with 404 page later on
      name: '404',
      component: NotFound,
    },
    {
      path: '/imprint',
      name: 'imprint',
      components: {
        default: Imprint,
        intro: Intro,
        navigation: Navigation,
        footer: Footer,
      },
    },
  ],
});
